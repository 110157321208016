export const environment = {
	firebaseConfig: {
		apiKey: "AIzaSyCoJsi7n2dSG4EhAoRIkFtXQpqpwCkp1IY",
		authDomain: "snoofa-real-time-stg.firebaseapp.com",
		projectId: "snoofa-real-time-stg",
		storageBucket: "snoofa-real-time-stg.appspot.com",
		messagingSenderId: "387931999320",
		appId: "1:387931999320:web:4cf51f3008921dbf238e2d",
		measurementId: "G-DBZQ77LFR8"
	},

	production: true,

	firestore: {
		databaseId: '(default)'
	}
};
